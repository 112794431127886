import { useEffect } from 'react'

import { useSafeState } from 'ahooks'

export const useIsMountedMs = (isOpened = false, setIsMounted?: (isMounted: boolean) => void, ms = 300) => {
  const [isMounted, setIsMountedInternal] = useSafeState(isOpened)

  useEffect(() => {
    let timer: NodeJS.Timeout
    setIsMountedInternal((prev) => {
      if (!prev) {
        setIsMounted?.(isOpened)
        return isOpened
      }
      if (!isOpened) {
        timer = setTimeout(() => {
          setIsMounted?.(false)
          setIsMountedInternal(false)
        }, ms)
      }
      setIsMounted?.(prev)
      return prev
    })
    return () => {
      clearTimeout(timer)
    }
  }, [isOpened])

  return [isMounted, setIsMountedInternal]
}
