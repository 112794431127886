// Цвета на кнопке Изи
export const colorsAnimationBackground = ['#52e6e7', '#aefbfb', '#2ae6e7', '#97eff0']

// Скорость
export const speedAnimationButtonEasy = 1.5

// Размытие (процент от минимальной стороны)
// т.е размытие в пикселях = min(width, height) * blurAnimationButtonEasy
export const blurAnimationButtonEasy = 0.1

// Количество кругов (50 - значение, которое подходит для большого фона)
export const numberOfCirclesButtonEasy = 20
