import { FC } from 'react'

import cx from 'clsx'

import classes from './Skeleton.module.scss'

export enum SkeletonSizes {
  Default = 'default',
  Large = 'large',
  Small = 'small',
}

export enum SkeletonTypes {
  Line = 'line',
  Tabs = 'tabs',
}

interface SkeletonProps {
  className?: string
  size?: SkeletonSizes
  type?: SkeletonTypes
  count?: number
  width?: number
  margin?: number
  disableAnimation?: boolean
}

export const Skeleton: FC<SkeletonProps> = ({
  className,
  size = SkeletonSizes.Default,
  type = SkeletonTypes.Line,
  count = 1,
  width,
  margin,
  disableAnimation,
}) => (
  <>
    {Array.from(new Array(count)).map((_, index) => (
      <div
        className={cx(classes.wrap, className, classes[size], classes[type], {
          [classes.disableAnimation]: disableAnimation,
        })}
        key={index}
        style={{ width, margin: margin !== undefined ? `${margin}px 0` : undefined }}
      />
    ))}
  </>
)
