export const calcGridPos = (target: HTMLElement) => {
  const td = target.closest('td') as HTMLTableCellElement
  const th = target.closest('th') as HTMLTableCellElement
  const element = td ?? th
  let rowIdx = 0,
    columnIdx = 0,
    detected = false
  if (element && element.colSpan === 1) {
    const cell = element.parentElement as HTMLTableRowElement
    rowIdx = cell?.rowIndex
    columnIdx = element?.cellIndex
    detected = true
  }
  return {
    rowIdx,
    columnIdx,
    detected,
  }
}
