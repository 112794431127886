import { CSSProperties, forwardRef, ReactNode, useEffect, useState } from 'react'

import cx from 'clsx'

import classes from './Switch.module.scss'

export enum SwitchSizes {
  Medium = 'medium',
  Small = 'small',
}

export enum SwitchColors {
  Default = 'default',
  Primary = 'primary',
}

export enum SwitchVariants {
  Default = 'default',
  Gradient = 'gradient',
}

interface SwitchProps {
  className?: string
  classNameLabel?: string
  label?: ReactNode
  size?: SwitchSizes
  value?: boolean
  onChange?: (value: boolean) => void
  disabled?: boolean
  style?: CSSProperties
  children?: ReactNode
  noEditable?: boolean
  color?: SwitchColors
  variant?: SwitchVariants
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      className,
      classNameLabel,
      label,
      size = SwitchSizes.Medium,
      value,
      onChange,
      disabled,
      noEditable,
      style,
      children,
      color = SwitchColors.Default,
      variant = SwitchVariants.Default,
    },
    ref,
  ) => {
    const [valueInternal, setValueInternal] = useState(value)

    const onChangeInternal = () => {
      setValueInternal((prev) => {
        window.requestAnimationFrame(() => onChange?.(!prev))
        return !prev
      })
    }

    useEffect(() => {
      setValueInternal(!!value)
    }, [value])

    return (
      <div
        className={cx(classes.wrap, className, classes[size], {
          [classes.active]: valueInternal,
          [classes.disabled]: disabled,
          [classes.noLabel]: !label,
          [classes.noEditable]: noEditable,
        })}
        ref={ref}
        style={style}
      >
        <div
          className={cx(classes.cont, className, classes[color], classes[variant], {
            [classes.active]: valueInternal,
            [classes.disabled]: disabled,
            [classes.noLabel]: !label,
          })}
          onClick={onChangeInternal}
        >
          <div className={classes.input} />
          {label && <div className={cx(classes.label, classNameLabel)}>{label}</div>}
        </div>
        {children && <div className={classes.children}>{children}</div>}
      </div>
    )
  },
)
