const isNumberWithCommaDelimiter = (str: string) => {
  const strWithDot = str.replace(',', '.')
  return !isNaN(Number(strWithDot))
}

export const commaFormatNumber = (str?: string | null) => {
  if (str && !isNaN(Number(str))) {
    return str.replace('.', ',')
  } else {
    return str
  }
}

export const dotFormatNumber = (str: string | null) => {
  if (str && isNumberWithCommaDelimiter(str)) {
    return str.replace(',', '.')
  } else {
    return str
  }
}
