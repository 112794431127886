import { RefObject, useEffect } from 'react'

export const useIntersectionObserver = (
  el: RefObject<HTMLElement>,
  cb: (isInViewport: boolean) => void,
  options?: IntersectionObserverInit & { disable?: boolean },
  deps?: any[],
) => {
  useEffect(() => {
    if (!el?.current || options?.disable) {
      return
    }
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => cb(entry.isIntersecting))
    }, options)
    intersectionObserver.observe(el.current)
    return () => {
      intersectionObserver.disconnect()
    }
  }, [el?.current, options?.threshold, options?.root, options?.rootMargin, options?.disable, ...(deps || [])])
}
