import { isEqual, xor } from 'lodash'
import { CustomerTableRow, CustomerTableRowValues } from 'prgm/customerSplit/interfaces'

export const getTotalBaseline = (rows: CustomerTableRow[], selectedIds: (string | number)[]) => {
  const total = Number(
    rows.reduce((acc, item) => acc + (selectedIds.includes(item.id) ? item.baseline : 0), 0).toFixed(2),
  )

  return total
}

export const getTotalVolume = (rows: CustomerTableRow[], selectedIds: (string | number)[]) => {
  const total = Number(
    rows.reduce((acc, item) => acc + (selectedIds.includes(item.id) ? item.volume : 0), 0).toFixed(2),
  )

  return total
}

export const checkHasManualBaseline = (rows: CustomerTableRow[], selectedIds: (string | number)[]) =>
  rows.filter((row) => selectedIds.includes(row.id)).some((row) => row.isManualBaseline)

export const checkCalculateParamsChanged = ({
  rowsValues,
  initialValues,
  selectedIds,
  initialSelectedIds,
}: {
  rowsValues: CustomerTableRowValues[]
  initialValues: CustomerTableRowValues[]
  selectedIds: (string | number)[]
  initialSelectedIds: (string | number)[]
}) => !!xor(initialSelectedIds, selectedIds).length || !isEqual(rowsValues, initialValues)

export const getRowsValues = (rows: CustomerTableRow[]) =>
  rows.map((row, index) => ({
    id: index,
    baseline: Number(row.baseline.toFixed(2)),
    volume: Number(row.volume.toFixed(2)),
  }))
