import { FC, ReactNode, useRef } from 'react'

import cx from 'clsx'
import { useSyncHeight } from 'hooks/useSyncHeight'

import classes from './Table.module.scss'

interface SelectThProps {
  children?: ReactNode
}

export const SelectTh: FC<SelectThProps> = ({ children }) => {
  const refTd = useRef<HTMLTableCellElement>(null)
  const refContent = useRef<HTMLDivElement>(null)

  useSyncHeight(refTd, refContent)

  return (
    <th className={classes.selectTd} ref={refTd}>
      <div className={cx(classes.selectTdCont, classes.disabledBorder)} ref={refContent}>
        {children}
      </div>
    </th>
  )
}
