import { Row } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'

import { getRowId } from './getRowId'

export const getParentIds = <TData extends TRow>(options: Row<TData>[], id: number | string) => {
  const itemIndex = options?.findIndex((option) => getRowId(option) === id)
  if (itemIndex === undefined || !options) {
    return []
  }

  const itemOption = options[itemIndex]
  let i = itemIndex - 1
  const result = []
  const rowData = itemOption?.original.data ?? itemOption?.original
  const itemLevel = rowData?._level
  let parentOptionData = options[i]?.original.data ?? options[i]?.original
  let parentLevel = parentOptionData?._level
  while (parentLevel !== undefined && itemLevel !== undefined && parentLevel !== 0) {
    if (parentLevel < itemLevel) {
      result.push(getRowId(options[i]))
    }
    i--
    parentOptionData = options[i]?.original.data ?? options[i]?.original
    parentLevel = parentOptionData?._level
  }
  if (parentLevel < itemLevel) {
    result.push(getRowId(options[i]))
  }

  return result
}
