import { FC, ReactNode } from 'react'

import SuccessIcon from 'assets/images/mark.svg?react'
import ErrorIcon from 'assets/images/refused-fill.svg?react'
import PendingIcon from 'assets/images/wait-bold.svg?react'
import cx from 'clsx'

import classes from './StatusCell.module.scss'

const statusToIcon: Record<string, ReactNode> = {
  pending: <PendingIcon className={cx(classes.pending)} height={16} width={16} />,
  success: <SuccessIcon className={cx('svg-img', classes.success)} height={16} width={16} />,
  error: <ErrorIcon className="svg-img" height={16} width={16} />,
}

interface Props {
  status?: string
}

export const StatusCell: FC<Props> = ({ status }) => {
  if (status) {
    return (
      <td className={classes.statusCell}>
        <div className={classes.icon}>{statusToIcon[status]}</div>
      </td>
    )
  }

  return (
    <td className={classes.statusCell}>
      <div className={classes.icon}>&nbsp;</div>
    </td>
  )
}
