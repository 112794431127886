import { ExcelCellData, ExcelState } from 'interfaces/excelTable.interfaces'

export const getCell = <State extends ExcelState = ExcelState>(
  state: State,
  rowIndex: number,
  columnIndex: number,
): ExcelCellData | null => {
  if (!state.perPage) {
    return null
  }
  const page =
    state.paginateAxis === 'x' ? Math.floor(columnIndex / state.perPage) + 1 : Math.floor(rowIndex / state.perPage) + 1
  const column = state.paginateAxis === 'x' ? columnIndex - (page - 1) * state.perPage : columnIndex
  const row = state.paginateAxis === 'y' ? rowIndex - (page - 1) * state.perPage : rowIndex

  return state.data?.[page]?.cells[row]?.[column] || null
}
