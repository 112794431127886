type BorderRadius = {
  borderTopLeftRadius: string | undefined
  borderTopRightRadius: string | undefined
  borderBottomLeftRadius: string | undefined
  borderBottomRightRadius: string | undefined
}

export const getBorderRadius = (borderRadius?: string | number): BorderRadius => {
  if (!borderRadius) {
    return {
      borderTopLeftRadius: undefined,
      borderTopRightRadius: undefined,
      borderBottomLeftRadius: undefined,
      borderBottomRightRadius: undefined,
    }
  }
  if (typeof borderRadius === 'number') {
    return {
      borderTopLeftRadius: `${borderRadius}px`,
      borderTopRightRadius: `${borderRadius}px`,
      borderBottomLeftRadius: `${borderRadius}px`,
      borderBottomRightRadius: `${borderRadius}px`,
    }
  }
  const values = borderRadius.split(' ')
  switch (values.length) {
    case 1:
      return {
        borderTopLeftRadius: values[0],
        borderTopRightRadius: values[0],
        borderBottomLeftRadius: values[0],
        borderBottomRightRadius: values[0],
      }
    case 2:
      return {
        borderTopLeftRadius: values[0],
        borderTopRightRadius: values[1],
        borderBottomLeftRadius: values[0],
        borderBottomRightRadius: values[1],
      }
    case 3:
      return {
        borderTopLeftRadius: values[0],
        borderTopRightRadius: values[1],
        borderBottomLeftRadius: values[2],
        borderBottomRightRadius: values[1],
      }
    case 4:
      return {
        borderTopLeftRadius: values[0],
        borderTopRightRadius: values[1],
        borderBottomLeftRadius: values[2],
        borderBottomRightRadius: values[3],
      }
    default:
      return {
        borderTopLeftRadius: undefined,
        borderTopRightRadius: undefined,
        borderBottomLeftRadius: undefined,
        borderBottomRightRadius: undefined,
      }
  }
}
