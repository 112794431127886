import { createElement, ElementType, forwardRef, ReactNode } from 'react'

import cx from 'clsx'

import classes from './Draggable.module.scss'

interface DragElementProps {
  className?: string
  children?: ReactNode
  enabled?: boolean
  as?: ElementType
}

export const DragElement = forwardRef<HTMLTableCellElement, DragElementProps>(
  ({ className, children, enabled = true, as = 'div' }, ref) =>
    createElement(
      as,
      {
        className: cx(classes.dragElement, className),
        'data-drag-element': enabled,
        ref,
      },
      children,
    ),
)
