import { SelectedCells } from 'interfaces/excelTable.interfaces'

export const defaultSelectedCells: SelectedCells = {
  fixed: {
    columns: { start: -1, end: -1 },
    rows: { start: -1, end: -1 },
  },
  cells: {
    columns: { start: -1, end: -1 },
    rows: { start: -1, end: -1 },
  },
}
