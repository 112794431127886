import { MutableRefObject, useEffect } from 'react'

import { useRafState } from 'ahooks'
import { getTargetElement } from 'utils/getTargetElement'

type TargetValue<T> = T | undefined | null
type TargetType = HTMLElement | Element | Window | Document
export type BasicTarget<T extends TargetType = Element> =
  | (() => TargetValue<T>)
  | TargetValue<T>
  | MutableRefObject<TargetValue<T>>

type Size = {
  width: number
  height: number
}

export const useSize = (target: BasicTarget, options?: { enabled: boolean }): Size | undefined => {
  const [state, setState] = useRafState<Size | undefined>(() => {
    const el = getTargetElement(target)
    return el ? { width: el.clientWidth, height: el.clientHeight } : undefined
  })

  useEffect(() => {
    if (options?.enabled === false) {
      setState(undefined)
      return
    }
    const el = getTargetElement(target)

    if (!el) {
      return
    }

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { clientWidth, clientHeight } = entry.target
        setState((prev) => {
          if (prev?.width === clientWidth && prev?.height === clientHeight) {
            return prev
          }
          return { width: clientWidth, height: clientHeight }
        })
      })
    })
    resizeObserver.observe(el)
    return () => {
      resizeObserver.disconnect()
    }
  }, [target, options?.enabled])

  return state
}
