import { FC, ReactNode, useRef } from 'react'

import cx from 'clsx'
import { useSyncHeight } from 'hooks/useSyncHeight'

import classes from './Table.module.scss'

interface SelectTdProps {
  children?: ReactNode
}

export const SelectTd: FC<SelectTdProps> = ({ children }) => {
  const refTd = useRef<HTMLTableCellElement>(null)
  const refContent = useRef<HTMLDivElement>(null)

  useSyncHeight(refTd, refContent)

  return (
    <td className={cx(classes.selectTd, 'selectTd')} ref={refTd}>
      <div className={cx(classes.selectTdCont, 'selectTdCont')} ref={refContent}>
        {children}
      </div>
    </td>
  )
}
