import { TablePagination } from 'interfaces/components.interfaces'
import { deleteFalsy } from 'packages/helper'

const paginationHeight = 52

export const calculateLoaderHeight = (params: {
  rowHeight?: number
  loaderHeight?: number
  maxHeightLines?: number
  pagination?: TablePagination
}) => {
  const showPagination = params.pagination && params.pagination.total > (params.pagination.pageSize || 0)
  const rowsHeight = params.maxHeightLines
    ? params.maxHeightLines * (params.rowHeight || 33) + (!showPagination ? paginationHeight : 0)
    : undefined
  const height = params.loaderHeight || rowsHeight
  return deleteFalsy({ height, maxHeight: height })
}
