export function hexToRgb(hex: string, alpha: number = 1) {
  if (hex.charAt && hex.charAt(0) === '#') {
    hex = removeHash(hex)
  }

  if (hex.length === 3) {
    hex = expand(hex)
  }

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return [r, g, b, alpha]
}

export function hexToRgbString(hex: string, alpha: number = 1) {
  const rgb = hexToRgb(hex, alpha)

  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${rgb[3]})`
}

function removeHash(hex: string) {
  const arr = hex.split('')
  arr.shift()
  return arr.join('')
}

function expand(hex: string) {
  return hex
    .split('')
    .reduce(function (accum, value) {
      return accum.concat([value, value])
    }, [] as string[])
    .join('')
}
