export const getClassesForId = (classesRows?: Record<string, number[]>, rowId?: number): string[] => {
  if (!rowId) {
    return []
  }

  return Object.entries(classesRows || {}).reduce<string[]>(
    (acc, [className, rows]) => (rows.includes(rowId) ? [...acc, className] : acc),
    [],
  )
}
