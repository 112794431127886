import { RefObject, useEffect } from 'react'

import { isElement } from 'lodash'

interface ResizeObserverArgs {
  ref: RefObject<HTMLElement> | HTMLElement | undefined | null
  callback: MutationCallback
  dependencies?: any[]
  enabled?: boolean
  options?: MutationObserverInit
  runImmediately?: boolean
}

export const useMutationObserver = ({
  ref,
  callback,
  dependencies,
  enabled = true,
  options,
  runImmediately,
}: ResizeObserverArgs) => {
  const element = isElement(ref) ? ref : (ref as any)?.current
  useEffect(() => {
    if (!element || !enabled) {
      return
    }
    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(element, options)
    if (runImmediately) {
      // @ts-ignore
      callback()
    }
    return () => {
      mutationObserver.disconnect()
    }
  }, [element, enabled, ...(dependencies || [])])
}
