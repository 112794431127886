import { MutableRefObject, ReactNode } from 'react'
import { ConnectDropTarget } from 'react-dnd'

import { Header, Table } from '@tanstack/react-table'
import { TRow } from 'interfaces/table.interfaces'

import { useDragNDrop } from './hooks/useDragNDrop'

interface Props<TData extends TRow> {
  header: Header<TData, unknown>
  table: Table<TData>
  containerRef: MutableRefObject<ConnectDropTarget | undefined>
  children?: ReactNode
}

export const DragWrap = <TData extends TRow>(props: Props<TData>) => {
  const { table, header, children, containerRef } = props
  const { dropRef, dragRef, previewRef } = useDragNDrop(table, header)
  containerRef.current = dropRef
  return (
    <div ref={previewRef}>
      <div ref={dragRef}>{children}</div>
    </div>
  )
}
