import { Row } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'

import { getRowId } from './getRowId'

export const getCheckedChildren = <TData extends TRow>(
  options: Row<TData>[],
  checkedValues: (string | number | null)[],
) => {
  if (!options) {
    return []
  }
  const result: (string | number)[] = []
  for (let i = 0; i < options.length; i++) {
    const rowId = getRowId(options[i])
    if (checkedValues.includes(rowId)) {
      result.push(rowId)

      const parentOption = options[i]?.original.data ?? options[i]?.original
      const parentLevel = parentOption?._level
      let itemOption = options[i + 1]?.original.data ?? options[i + 1]?.original
      let itemLevel = itemOption?._level

      if (itemLevel > parentLevel) {
        i++
        while (itemLevel !== undefined && parentLevel !== undefined && itemLevel > parentLevel) {
          if (!itemOption._disableSelect) {
            result.push(getRowId(options[i]))
          }
          i++
          itemOption = options[i]?.original.data ?? options[i]?.original
          itemLevel = itemOption?._level
        }
        i--
      }
    }
  }

  return result
}
