import { Row } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'

import { getRowId } from './getRowId'

export const getChildrenItems = <TData extends TRow>(options: Row<TData>[], id: number | string) => {
  const itemIndex = options?.findIndex((option) => getRowId(option) === id)
  if (itemIndex === undefined || !options) {
    return []
  }

  const itemOption = options[itemIndex]
  let i = itemIndex + 1
  const result = []
  const rowData = itemOption?.original.data ?? itemOption?.original
  const parentLevel = rowData?._level
  let childOptionData = options[i]?.original.data ?? options[i]?.original
  let childLevel = childOptionData?._level
  while (childLevel !== undefined && parentLevel !== undefined && childLevel > parentLevel) {
    if (!childOptionData._disableSelect) {
      result.push(getRowId(options[i]))
    }
    i++
    childOptionData = options[i]?.original.data ?? options[i]?.original
    childLevel = childOptionData?._level
  }

  return result
}
