import {
  Dispatch,
  FC,
  forwardRef,
  Fragment,
  HTMLAttributes,
  memo,
  MutableRefObject,
  ReactElement,
  ReactNode,
  UIEvent as ReactUIEvent,
  Ref,
  RefObject,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'

import {
  ColumnDef,
  ColumnDefTemplate,
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  Getter,
  NoInfer,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { CellContext, ColumnOrderState } from '@tanstack/table-core'
import { useDeepCompareEffect } from 'ahooks'
import InfoIcon from 'assets/images/info.svg?react'
import LockIcon from 'assets/images/lock.svg?react'
import PlusIcon from 'assets/images/plus-mini.svg?react'
import cx from 'clsx'
import { TextModal } from 'components/modals/TextModal'
import { useTranslate } from 'config/i18n'
import { SELECT_ALL } from 'constants/components'
import { cellSizeToRowHeight, defaultRowHeight } from 'constants/table'
import { useModal } from 'hooks/useModal'
import { TablePagination, TextFieldColors } from 'interfaces/components.interfaces'
import { TableCommonProps, TRow } from 'interfaces/table.interfaces'
import { flatten, intersection, isEqual, last, uniq } from 'lodash'
import { deleteUndefined, deleteUndefinedInArray } from 'packages/helper'
import { Checkbox } from 'ui/Checkbox'
import { Loader, LoaderColors, LoaderTypes } from 'ui/Loader'
import { Pagination } from 'ui/Pagination'
import { Skeleton } from 'ui/Skeleton'
import { SelectableCellParams } from 'ui/Table/hooks/useSelectableCell'
import { Tag, TagColors } from 'ui/Tag'
import { Tooltip } from 'ui/Tooltip'
import { isMouseEvent } from 'utils/isMouseEvent'
import { mergeObjects } from 'utils/mergeObjects'
import { numberToLocalString } from 'utils/numberToLocalString'

import { DefaultTableCell } from './components/DefaultTableCell'
import { RowSelectArea } from './components/RowSelectArea'
import { SelectionFrame } from './components/SelectionFrame'
import { TableContextMenu } from './components/TableContextMenu'
import { calculateLoaderHeight } from './helpers/calculateLoaderHeight'
import { getBorderRadius } from './helpers/getBorderRadius'
import { getCellProps } from './helpers/getCellProps'
import { getCheckedChildren } from './helpers/getCheckedChildren'
import { getChildrenItems } from './helpers/getChildrenItems'
import { getColumnProps } from './helpers/getColumnProps'
import { getFooterSelection } from './helpers/getFooterSelection'
import { getParentIds } from './helpers/getParentIds'
import { getRenderedHeader } from './helpers/getRenderedHeader'
import { getRowId } from './helpers/getRowId'
import { getValueCell } from './helpers/getValueCell'
import { useCollapsing } from './hooks/useCollapsing'
import { useColumnOrder } from './hooks/useColumnOrder'
import { useFormatByCollapseStrategy } from './hooks/useFormatByCollapseStrategy'
import { useMatrix } from './hooks/useMatrixEditable'
import { useRenderedRows } from './hooks/useRenderedRows'
import { useResizeLastColumn } from './hooks/useResizeLastColumn'
import { useTableSelection } from './hooks/useTableSelection'
import { InitContextMenu, OnRowClick, OnRowContextMenu } from './interfaces'
import { SelectTd } from './SelectTd'
import { SelectTh } from './SelectTh'
import classes from './Table.module.scss'
import { TableCell } from './TableCell'
import { TBody } from './TBody'
import { CollapsingStrategy, DraggableStrategy, RowId, RowIdNotNullable } from './types'

export enum TextSize {
  Medium = 'medium',
  Small = 'small',
}

export interface TableProps<TData extends TRow> {
  className?: string
  classNameContainer?: string
  classNameScroll?: string
  columnOrder?: ColumnOrderState
  copyPaste?: boolean
  headerRowClassName?: string
  footerRowClassName?: string
  tableWrapClassName?: string
  tableContClassName?: string
  deleteIconClassName?: string
  rowClassName?: string
  rowSelectedClassName?: string
  emptyTextContClassName?: string
  emptyTextToLeft?: boolean
  data?: TData[] | null
  emptyText?: ReactNode
  showEmptyText?: boolean
  columns?: ColumnDef<TData, any>[]
  showBorder?: boolean
  showBorderRow?: boolean
  showBorderCell?: boolean
  showHeader?: boolean
  showFooter?: boolean
  forceDeleteTooltip?: boolean
  onRow?: (row: TData) => HTMLAttributes<HTMLTableRowElement>
  isEditableAlways?: boolean
  dashIfEmpty?: boolean
  trackViewport?: boolean
  updateData?: (value: string, columnId: string, rowIndex: number) => void
  setData?: (value: string, columnId: string, rowIndex: number) => void
  applyEditData?: () => void
  onChangeInput?: (value: string, columnId: string, rowIndex: number) => void
  onViewPort?: (columnId: string, inViewport: boolean) => void
  refFirstColumn?: MutableRefObject<HTMLTableCellElement | null>
  refColumns?: MutableRefObject<(HTMLTableCellElement | null)[]>
  getType?: (value: string) => TextFieldColors
  fullWidth?: boolean
  textSize?: TextSize
  loading?: boolean
  loadingWithSkeletons?: number
  reloading?: boolean
  loadingUpdateButton?: boolean
  loaderHeight?: number
  selectable?: boolean
  selectAll?: boolean
  selectPosition?: 'left' | 'right'
  mayReturnIds?: number[]
  onReturn?: (id: number) => void
  selectedIds?: any[]
  setSelectedIds?: Dispatch<SetStateAction<any[]>> | ((values: any) => void)
  onSelect?: (id: any) => void
  onDeselect?: (id: any) => void
  archivedIds?: number[]
  pagination?: TablePagination
  editable?: boolean
  createdRows?: TData[] | null
  onCreate?: () => void
  onRemoveNew?: (index: number) => void
  canReturnFromArchive?: boolean
  blockedIds?: (number | string)[]
  disabledIds?: (number | string)[]
  disableScroll?: boolean
  widthFullAlways?: boolean
  deletable?: boolean
  deleteActiveIds?: any[]
  deleteInactiveTooltip?: ReactNode
  onDelete?: (id: any) => void
  inputHasChanged?: boolean
  scrollVertical?: boolean
  maxHeightLines?: number
  loaderClassName?: string
  errorMessage?: ReactNode
  errorDetail?: string | null
  classNameExpanded?: string
  classNameCollapsedRow?: string
  expandedRows?: RowIdNotNullable[]
  setExpandedRows?: Dispatch<SetStateAction<RowIdNotNullable[]>>
  classesRows?: Record<string, number[]>
  setIsDataApplied?: (value: boolean) => void
  setColumnOrder?: (columnsOrder: ColumnOrderState) => void
  isDataApplied?: boolean
  newRowData?: Record<string, number | null>
  showCountInfo?: boolean
  maxRowCount?: number
  onInfiniteScroll?: () => void
  virtualize?: boolean
  virtualOverscan?: number
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  onRowClick?: OnRowClick<TData>
  onRowContextMenu?: OnRowContextMenu<TData>
  columnResizable?: boolean
  columnResizeMode?: ColumnResizeMode
  columnDraggable?: boolean
  toBlocks?: boolean
  maxWidth100?: boolean
  truncateHeaders?: boolean
  getMeta?: () => any
  hoverOnRow?: boolean
  onScroll?: (event: ReactUIEvent<HTMLDivElement, UIEvent>) => void
  onResize?: (columnId: number | string, size: number, isAutoWidthLastColumn: boolean) => void
  total?: number
  showTotalTooltip?: boolean
  errorsIds?: RowId[]
  isCollapsing?: boolean
  collapsingComponent?: FC<{ row?: TData }>
  isCollapsedRow?: (row: TData) => boolean
  collapsingTreeKeys?: string[]
  collapsingStrategy?: CollapsingStrategy
  collapsingInitialOpen?: boolean
  isDraggable?: boolean
  draggableStrategy?: DraggableStrategy
  draggableOnChange?: (oldIndex: number, newIndex: number, item: any | null, into: any | null) => void
  draggableGetDescription?: (meta: any | null) => string
  draggableIsGlobal?: boolean
  draggableOnPaste?: (newIndex: number, item: any, into: any) => void
  mappedGroups?: Record<string, string[]>
  cellSize?: 'small' | 'default'
  showStatus?: boolean
  refScroll?: RefObject<HTMLDivElement>
  refScrollVirtual?: RefObject<HTMLDivElement>
  refCont?: RefObject<HTMLDivElement>
  selectableCellParams?: SelectableCellParams
}

const InternalTable = <TData extends TRow>(
  {
    className,
    classNameContainer,
    classNameScroll,
    columnOrder,
    copyPaste,
    headerRowClassName,
    footerRowClassName,
    tableWrapClassName,
    tableContClassName,
    deleteIconClassName,
    rowClassName,
    rowSelectedClassName,
    emptyTextContClassName,
    emptyTextToLeft,
    data,
    columns,
    emptyText,
    showEmptyText = true,
    showBorder = false,
    showBorderRow = true,
    showBorderCell = false,
    showHeader = true,
    showFooter = true,
    forceDeleteTooltip,
    onRow,
    isEditableAlways = false,
    dashIfEmpty = false,
    trackViewport = false,
    updateData,
    setData,
    applyEditData,
    onChangeInput,
    onViewPort,
    refFirstColumn,
    refColumns,
    getType,
    fullWidth,
    textSize = TextSize.Small,
    loading,
    loadingWithSkeletons,
    loadingUpdateButton,
    reloading,
    loaderHeight,
    selectable,
    selectAll,
    selectPosition = 'right',
    mayReturnIds,
    onReturn,
    selectedIds,
    setSelectedIds,
    onSelect,
    onDeselect,
    archivedIds,
    pagination,
    editable,
    createdRows,
    onCreate,
    onRemoveNew,
    canReturnFromArchive,
    blockedIds,
    disabledIds,
    disableScroll,
    widthFullAlways,
    deletable,
    deleteActiveIds,
    deleteInactiveTooltip,
    onDelete,
    inputHasChanged,
    scrollVertical,
    maxHeightLines,
    loaderClassName,
    errorMessage,
    errorDetail,
    classNameExpanded,
    classNameCollapsedRow,
    expandedRows = [],
    setExpandedRows,
    classesRows,
    setIsDataApplied,
    setColumnOrder,
    isDataApplied,
    newRowData,
    showCountInfo,
    maxRowCount,
    onInfiniteScroll,
    virtualize,
    virtualOverscan = 10,
    hasNextPage,
    isFetchingNextPage,
    onRowClick,
    onRowContextMenu,
    columnResizable,
    columnResizeMode = 'onChange',
    columnDraggable,
    toBlocks,
    maxWidth100,
    truncateHeaders,
    getMeta,
    hoverOnRow,
    onScroll,
    onResize,
    errorsIds,
    total = 0,
    showTotalTooltip,
    isCollapsing,
    collapsingComponent,
    isCollapsedRow,
    collapsingTreeKeys,
    collapsingStrategy,
    collapsingInitialOpen,
    isDraggable,
    draggableStrategy = 'rows',
    draggableOnChange,
    draggableIsGlobal,
    draggableGetDescription,
    draggableOnPaste,
    mappedGroups,
    cellSize,
    showStatus,
    refScroll,
    refScrollVirtual,
    refCont,
    selectableCellParams,
  }: TableProps<TData>,
  ref?: Ref<HTMLTableElement>,
): ReactElement => {
  const refWrap = useRef<HTMLDivElement>(null)
  const refScrollInternal = useRef<HTMLDivElement>(null)
  const refLastSelect = useRef<number | string>()
  const tableRef = useRef<HTMLTableElement>(null)
  const rowHeight = cellSize ? cellSizeToRowHeight[cellSize] : defaultRowHeight
  const { columnOrderInternal, setColumnOrderInternal } = useColumnOrder(columns, columnOrder, setColumnOrder)
  const [openError, onOpenError, onCloseError] = useModal()
  useImperativeHandle(ref, () => tableRef.current as HTMLTableElement)
  useImperativeHandle(refScroll, () => refScrollInternal.current as HTMLDivElement)
  const { formattedRows } = useFormatByCollapseStrategy({ collapsingStrategy, data, isCollapsing, mappedGroups })
  const rowClassNameInternal = cx(rowClassName, {
    [classes.rowSmallCell]: cellSize === 'small',
  })
  const { sizeLastColumn } = useResizeLastColumn({
    refWrap,
    tableRef,
    columnResizable,
    widthFullAlways,
    onResize,
    columns,
    showHeader,
  })

  const refContextMenuInitializer = useRef<InitContextMenu>()

  const {
    formattedData,
    isCollapsed,
    toggleCollapse,
    expandedRowsInternal,
    isParent,
    isMouseOverParent,
    isMouseOutParent,
    hoverParent,
  } = useCollapsing({
    data: formattedRows,
    collapsingTreeKeys,
    isCollapsing,
    collapsingStrategy,
    expandedRows,
    setExpandedRows,
    collapsingInitialOpen,
  })

  const CollapsingComponent = collapsingComponent
  const translate = useTranslate()

  const isCollapsedRowInternal = useMemo(() => isCollapsedRow || (() => !!collapsingComponent), [isCollapsedRow])

  const defaultColumn: Partial<ColumnDef<TData, any>> = {
    cell: (props) => <DefaultTableCell {...props} />,
  }

  const dataRows = useMemo(
    () =>
      [
        ...(formattedData ?? []),
        ...(createdRows?.map((row, index) => ({
          data: row.data ?? row,
          props: {
            ...row.props,
            isNew: true,
            index,
          },
        })) ?? []),
      ] as TData[],
    [formattedData, createdRows],
  )

  const showCountRowsInfo = useMemo(
    () => showCountInfo && !!maxRowCount && dataRows.length > 0 && dataRows.length < maxRowCount,
    [dataRows],
  )

  const table = useReactTable({
    data: dataRows,
    columns: columns ?? [],
    defaultColumn,
    state: {
      columnOrder: columnDraggable ? columnOrderInternal : undefined,
    },
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData,
      onChangeInput,
      setData,
      applyEditData,
      inputHasChanged,
      loadingUpdateButton,
      setIsDataApplied,
      isDataApplied,
      newRowData,
    },
    onColumnOrderChange: columnDraggable ? setColumnOrderInternal : undefined,
    columnResizeMode: columnResizable ? columnResizeMode : undefined,
  })

  const rows = table.getRowModel().rows
  const colExtra = data?.length
    ? Number(!!mayReturnIds?.length) +
      Number(!!selectable) +
      Number(!!deletable) +
      Number(!!isCollapsing) +
      Number(!!isDraggable)
    : 0
  const colSpan = (last(table.getHeaderGroups())?.headers.length || 0) + colExtra
  const matrix = useMatrix(rows, isEditableAlways && !showBorder && !showBorderCell, [columns])

  const showHeaderInternal =
    showHeader &&
    !!deleteUndefinedInArray(
      flatten(
        table
          .getHeaderGroups()
          .map((headerGroup) => headerGroup.headers.map((header) => header.column.columnDef.header)),
      ),
    ).length

  const showFooterInternal =
    showFooter &&
    !!deleteUndefinedInArray(
      flatten(
        table
          .getFooterGroups()
          .map((footerGroup) => footerGroup.headers.map((header) => header.column.columnDef.footer)),
      ),
    ).length

  const onSelectInternal = (id: number | typeof SELECT_ALL, selected: boolean) => {
    const childrenItems = id === SELECT_ALL ? [] : getChildrenItems(rows, id)
    const parentIds = !selected && id !== SELECT_ALL ? getParentIds(rows, id) : []
    const values = [id, ...parentIds, ...childrenItems]
    setSelectedIds?.((prev) => {
      let newState = []

      if (id === SELECT_ALL) {
        if (selected) {
          newState.push(
            SELECT_ALL,
            ...rows
              .filter((row) => {
                const rowData = row.original.data || row.original
                return !rowData.isFooter
              })
              .map((row) => getRowId(row)),
          )
          onSelect?.(SELECT_ALL)
        } else {
          newState = []
          onDeselect?.(SELECT_ALL)
        }
      } else {
        newState = [...prev]
        if (!selected && newState.includes(id)) {
          newState = newState.filter((item) => !values.includes(item) && item !== SELECT_ALL)
          onDeselect?.(id)
        }
        if (selected && !newState.includes(id)) {
          newState.push(...values)
          onSelect?.(id)
        }
      }

      return newState
    })
  }

  const onScrollInternal = () => {
    if (refScrollInternal.current && onInfiniteScroll) {
      const { scrollHeight, scrollTop, clientHeight } = refScrollInternal.current
      if (scrollHeight - scrollTop - clientHeight < 100 && !loading && hasNextPage && !isFetchingNextPage) {
        onInfiniteScroll()
      }
    }
  }

  const renderSelect = useCallback(
    (row: any) => {
      const rowId = getRowId(row)
      const rowData = row.original.data ?? row.original
      const isMayReturn = mayReturnIds?.includes(rowId)
      const isArchived = archivedIds?.includes(rowId)
      const isBlocked = blockedIds?.includes(rowId) || (blockedIds?.includes(SELECT_ALL) && !rowData.isFooter)
      const childrenItems = getChildrenItems(rows, rowId)
      const checkedChildren = intersection(childrenItems, selectedIds)
      const value = selectedIds?.includes(rowId)
      const isPartially = !value && !!checkedChildren.length

      return (
        <>
          {!isBlocked &&
            !!data?.length &&
            selectable &&
            (!isArchived || (isArchived && canReturnFromArchive)) &&
            !isMayReturn &&
            !row.original.props?.isNew &&
            !rowData._disableSelect && (
              <SelectTd key="select">
                <div className={classes.selectCheckbox}>
                  <Checkbox
                    disabled={isMayReturn}
                    isPartially={isPartially}
                    onChange={(selected, event) => {
                      if (rowId !== undefined) {
                        if (selected) {
                          if (refLastSelect.current !== undefined && isMouseEvent(event) && event.shiftKey) {
                            const startIndex = data?.findIndex((item) => (item as any).id === refLastSelect.current)
                            const endIndex = data?.findIndex((item) => (item as any).id === rowId)
                            const minIndex = Math.min(startIndex, endIndex)
                            const maxIndex = Math.max(startIndex, endIndex)
                            const slicedData = data?.slice(minIndex, maxIndex)
                            setSelectedIds?.((prev) => uniq([...prev, ...slicedData?.map((item) => (item as any).id)]))
                          }
                          refLastSelect.current = rowId
                        }
                        onSelectInternal?.(rowId, selected)
                      }
                    }}
                    value={selectedIds?.includes(rowId) || selectedIds?.includes('all')}
                  />
                </div>
              </SelectTd>
            )}
          {!!data?.length && (selectable || deletable) && isBlocked && !row.original.props?.isNew && (
            <SelectTd key="disabled">
              <div className={classes.disabledCont}>
                <LockIcon className={classes.disabledIcon} />
              </div>
            </SelectTd>
          )}
          {((!isBlocked && !!data?.length && selectable && row.original.props?.isNew && selectPosition === 'left') ||
            rowData._disableSelect) && <SelectTd key="empty">&nbsp;</SelectTd>}
        </>
      )
    },
    [mayReturnIds, archivedIds, blockedIds, canReturnFromArchive, selectable, deletable, data, selectedIds],
  )

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => (virtualize ? refScrollVirtual?.current ?? refScrollInternal.current : null),
    count: rows.length,
    overscan: virtualOverscan,
    estimateSize: () => rowHeight,
    measureElement: (element: HTMLTableRowElement) => element.offsetHeight,
  })

  const renderSelectAll = useCallback(() => {
    const selectedCount = selectedIds?.filter((el) => el !== SELECT_ALL).length || 0
    const isPartially = !!(selectedCount && total && selectedCount < total)

    const renderedTooltip = (
      <>
        {translate('totalCount')}: {total} <br />
        {translate('selectedCount')}: {selectedCount}
      </>
    )

    return (
      <>
        {!!data?.length && selectable && selectAll && (
          <SelectTh>
            <div className={classes.selectCheckbox}>
              <Tooltip className={classes.totalTooltip} enabled={showTotalTooltip} isHovered tooltip={renderedTooltip}>
                <Checkbox
                  disabled={blockedIds?.includes(SELECT_ALL)}
                  isPartially={isPartially}
                  onChange={(selected) => onSelectInternal(SELECT_ALL, selected)}
                  value={selectedIds?.includes(SELECT_ALL)}
                />
              </Tooltip>
            </div>
          </SelectTh>
        )}
        {!!data?.length && selectable && !selectAll && <SelectTh>&nbsp;</SelectTh>}
      </>
    )
  }, [data, selectable, selectAll, selectedIds, blockedIds, showTotalTooltip, total])

  const { getVirtualItems } = rowVirtualizer
  const virtualRows = getVirtualItems()

  const { renderedRows, virtualRenderedRows } = useRenderedRows({
    virtualize,
    virtualRows,
    rows,
    mayReturnIds,
    archivedIds,
    deleteActiveIds,
    inputHasChanged,
    isCollapsing,
    collapsingComponent,
    isCollapsedRowInternal,
    expandedRowsInternal,
    onRow,
    rowClassName: rowClassNameInternal,
    canReturnFromArchive,
    showCountRowsInfo,
    forceDeleteTooltip,
    classNameExpanded,
    classesRows,
    toggleCollapse,
    selectPosition,
    renderSelect,
    showFooterInternal,
    onRowClick,
    onRowContextMenu,
    initContextMenu: refContextMenuInitializer.current,
    columnResizable,
    dashIfEmpty,
    matrix,
    getType,
    data,
    deletable,
    onDelete,
    deleteInactiveTooltip,
    selectable,
    onReturn,
    deleteIconClassName,
    onRemoveNew,
    createdRows,
    CollapsingComponent,
    classNameCollapsedRow,
    colSpan,
    getMeta,
    selectedIds,
    rowSelectedClassName,
    errorsIds,
    disabledIds,
    collapsingTreeKeys,
    collapsingStrategy,
    isCollapsed,
    isParent,
    isMouseOverParent,
    isMouseOutParent,
    hoverParent,
    isDraggable,
    draggableStrategy,
    showStatus,
    totalSize: table.getCenterTotalSize(),
    rowVirtualizer,
    sizeLastColumn,
    selectableCellParams,
    tableRef,
  })

  const selectionAreaRef = useRef<HTMLDivElement>(null)
  const selection = useTableSelection(tableRef, copyPaste, selectionAreaRef)
  const { onTableMouseDown, onTableMouseUp } = selection
  const showCopyFrame = copyPaste && tableRef.current && !!selection && selection.active

  useDeepCompareEffect(() => {
    if (!selectedIds || !rows) {
      return
    }
    const values = getCheckedChildren(rows, selectedIds)
    if (!isEqual(selectedIds, values)) {
      setSelectedIds?.(values)
    }
  }, [data])

  return (
    <div
      className={cx(classes.wrap, tableWrapClassName, {
        [classes.disableScroll]: disableScroll,
        [classes.widthFullAlways]: widthFullAlways,
      })}
      data-table="true"
      ref={refWrap}
    >
      {loading && !loadingWithSkeletons && (
        <Loader
          className={loaderClassName}
          color={LoaderColors.Gray}
          data-loader="true"
          isBlock
          style={calculateLoaderHeight({ loaderHeight, rowHeight, maxHeightLines, pagination })}
          text
          type={LoaderTypes.Spinner}
        />
      )}
      {loading && loadingWithSkeletons && <Skeleton count={loadingWithSkeletons} />}
      {!loading && (
        <div
          className={cx(classes.relative, tableContClassName, {
            [classes.relativeFull]: fullWidth,
            [classes.scrollHorizontalWrap]: !disableScroll,
          })}
          style={maxHeightLines ? { maxHeight: maxHeightLines * rowHeight } : undefined}
        >
          {reloading && (
            <div className={classes.refetchBlock}>
              <Loader
                className={loaderClassName}
                color={LoaderColors.Gray}
                isBlock
                style={loaderHeight !== undefined ? { height: loaderHeight } : undefined}
                text
                type={LoaderTypes.Spinner}
              />
            </div>
          )}
          <div
            className={cx(classes.scrollHorizontal, classNameScroll, {
              scrollHorizontal: !disableScroll,
              scroll: scrollVertical,
              [classes.scrollVertical]: scrollVertical,
              [classes.fullWidth]: fullWidth,
            })}
            data-scroll-container="true"
            onScroll={(event) => (onInfiniteScroll ? onScrollInternal() : onScroll?.(event))}
            ref={refScrollInternal}
          >
            <div
              className={cx(classes.cont, classNameContainer, {
                [classes.selectableCont]:
                  (!!data?.length && selectPosition === 'right' && (selectable || deletable)) || !!createdRows?.length,
                [classes.maxWidth100]: maxWidth100,
                [classes.contWithDraggable]: isDraggable && !draggableIsGlobal,
              })}
              ref={refCont}
            >
              <table
                className={cx(classes.table, className, classes[textSize], {
                  [classes.showBorder]: showBorder,
                  [classes.showBorderRow]: showBorderRow,
                  [classes.showBorderCell]: showBorderCell,
                  [classes.showHeader]: showHeaderInternal,
                  [classes.showFooter]: showFooterInternal,
                  [classes.isEditableAlways]: isEditableAlways,
                  [classes.editable]: editable,
                  [classes.isCollapsing]: isCollapsing,
                  [classes.columnResizable]: columnResizable,
                  [classes.toBlocks]: toBlocks,
                  [classes.maxWidth100]: maxWidth100,
                  [classes.hoverOnRow]: hoverOnRow,
                  [classes.couldCopyPaste]: copyPaste,
                  [classes.tableIsDraggable]: isDraggable,
                })}
                onMouseDown={onTableMouseDown}
                onMouseUp={onTableMouseUp}
                ref={tableRef}
                style={{
                  width: !fullWidth && columnResizable ? table.getTotalSize() : undefined,
                  minWidth: fullWidth && !widthFullAlways && columnResizable ? table.getTotalSize() : undefined,
                }}
              >
                {showHeaderInternal && (
                  <thead>
                    {getRenderedHeader({
                      table,
                      isCollapsing,
                      rowClassName: rowClassNameInternal,
                      headerRowClassName,
                      selectPosition,
                      columnResizable,
                      data,
                      deletable,
                      colExtra,
                      createdRows,
                      trackViewport,
                      truncateHeaders,
                      refFirstColumn,
                      refColumns,
                      onViewPort,
                      onResize,
                      columnDraggable,
                      renderSelectAll,
                      copyPaste,
                      selection,
                      showBorderCell,
                      collapsingStrategy,
                      isDraggable,
                      widthFullAlways,
                    })}
                  </thead>
                )}

                <TBody
                  collapsingTreeKeys={collapsingTreeKeys}
                  draggableGetDescription={draggableGetDescription}
                  draggableIsGlobal={draggableIsGlobal}
                  draggableOnChange={draggableOnChange}
                  draggableOnPaste={draggableOnPaste}
                  draggableStrategy={draggableStrategy}
                  isDraggable={isDraggable}
                  refScroll={refScrollInternal}
                  style={
                    virtualize
                      ? {
                          display: 'grid',
                          height: `${rowVirtualizer.getTotalSize()}px`,
                          position: 'relative',
                        }
                      : undefined
                  }
                >
                  {!!rows.length ? (
                    virtualize ? (
                      virtualRenderedRows
                    ) : (
                      renderedRows
                    )
                  ) : errorMessage ? (
                    <tr>
                      <td colSpan={colSpan}>
                        <div className={classes.error}>
                          <Tag
                            color={TagColors.Danger}
                            isClickable={!!errorDetail}
                            onClick={onOpenError}
                            startIcon={<InfoIcon />}
                          >
                            {errorMessage}
                          </Tag>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    showEmptyText &&
                    !editable && (
                      <tr
                        className={classes.noData}
                        style={{
                          ...(virtualize
                            ? {
                                position: 'absolute',
                                display: 'flex',
                                width: '100%',
                              }
                            : {}),
                        }}
                      >
                        <td
                          colSpan={colSpan}
                          data-no-data="true"
                          style={{
                            ...(virtualize
                              ? {
                                  width: '100%',
                                }
                              : {}),
                          }}
                        >
                          <div
                            className={cx(classes.emptyText, emptyTextContClassName, {
                              [classes.emptyTextToLeft]: emptyTextToLeft,
                              [classes.emptyTextSmall]: cellSize === 'small',
                            })}
                          >
                            {emptyText ? emptyText : translate('noData')}
                          </div>
                        </td>
                      </tr>
                    )
                  )}

                  {editable && (
                    <tr>
                      <td colSpan={colSpan}>
                        <div className={classes.addButton} onClick={onCreate}>
                          <PlusIcon className={classes.plus} />
                          {translate('new')}
                        </div>
                      </td>
                      {((!!data?.length && (selectable || deletable)) || !!createdRows?.length) && (
                        <SelectTd key="empty">&nbsp;</SelectTd>
                      )}
                    </tr>
                  )}
                </TBody>

                {showFooter && (
                  <tfoot>
                    {showFooterInternal &&
                      table.getFooterGroups().map((footerGroup) => (
                        <tr className={cx(classes.row, rowClassNameInternal, footerRowClassName)} key={footerGroup.id}>
                          {!!data?.length && isDraggable && <th className={classes.draggableTd}>&nbsp;</th>}
                          {!!data?.length && isCollapsing && <th className={classes.arrowTd}>&nbsp;</th>}

                          {footerGroup.headers.map((header) => {
                            const { columnProps, tdProps } = getColumnProps(header.column)
                            const { borderBottomLeftRadius, borderBottomRightRadius } = getBorderRadius(
                              columnProps.style?.borderRadius,
                            )
                            return (
                              <th
                                className={tdProps.className}
                                colSpan={header.colSpan}
                                key={header.id}
                                style={{
                                  ...columnProps.style,
                                  borderRadius: undefined,
                                  borderBottomLeftRadius,
                                  borderBottomRightRadius,
                                }}
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.footer, header.getContext())}
                              </th>
                            )
                          })}
                          {!!data?.length && selectable && <SelectTh>&nbsp;</SelectTh>}
                          {!!data?.length && deletable && <SelectTh>&nbsp;</SelectTh>}
                          {!deletable && !selectable && !!createdRows?.length && <SelectTh>&nbsp;</SelectTh>}
                        </tr>
                      ))}
                    {rows.map((row, y) => {
                      const rowId = getRowId(row)

                      const rowData = row.original.data ?? row.original

                      if (!rowData.isFooter) {
                        return null
                      }

                      const isMayReturn = mayReturnIds?.includes(rowId)
                      const isArchived = archivedIds?.includes(rowId)

                      const isSaveEditByButton = row.original.props?.isSaveEditByButton && inputHasChanged

                      return (
                        <Fragment key={`${row.id}-${rowId}`}>
                          <tr
                            className={cx(
                              classes.row,
                              rowClassNameInternal,
                              footerRowClassName,
                              rowSelectedClassName && {
                                [rowSelectedClassName]:
                                  selectedIds?.includes(rowId) || selectedIds?.includes(SELECT_ALL),
                              },
                            )}
                            style={row.original.props?.style}
                          >
                            {selectPosition === 'left' && renderSelect(row)}
                            {row.getVisibleCells().map((cell, x) => {
                              const { columnProps, tdProps } = getColumnProps(cell.column)
                              const props = mergeObjects<TableCommonProps>(
                                deleteUndefined({ ...(row.original.props ?? {}), style: undefined }),
                                tdProps,
                                getCellProps(row.original, cell.column.id),
                              )
                              let borderBottomLeftRadius
                              let borderBottomRightRadius
                              if (!showFooterInternal && y === rows.length - 1) {
                                const radius = getBorderRadius(columnProps.style?.borderRadius)
                                borderBottomLeftRadius = radius.borderBottomLeftRadius
                                borderBottomRightRadius = radius.borderBottomRightRadius
                              }

                              const isSaveButton = cell.column.id === 'name' && isSaveEditByButton
                              const { isSelected, isStart, isEnd, inProgress, haveEdgeBorder } =
                                getFooterSelection({ index: x, tableRef, selection, selectable, copyPaste }) || {}

                              return (
                                <td
                                  className={cx(props.className, {
                                    [classes.smallPadding]: isSaveButton,
                                    [classes.selectedBack]: isSelected,
                                    [classes.selectStart]: isStart,
                                    [classes.selectEnd]: isEnd,
                                    [classes.borderBottom]: !inProgress,
                                    [classes.borderTop]: haveEdgeBorder && !inProgress,
                                  })}
                                  data-disabled={props.disabled}
                                  data-editable={props.isEditable}
                                  data-type={props.type}
                                  key={cell.id}
                                  style={{
                                    ...props.style,
                                    borderBottomLeftRadius,
                                    borderBottomRightRadius,
                                    textAlign: props.isNumberCellAlignRight ? 'right' : props.style?.textAlign,
                                    whiteSpace: props.isNumberCellAlignRight ? 'nowrap' : props.style?.whiteSpace,
                                    width: columnResizable ? cell.column.getSize() : props.style?.width,
                                  }}
                                >
                                  {flexRender(
                                    (props.isEditable || isSaveButton
                                      ? TableCell
                                      : cell.column.columnDef.cell) as ColumnDefTemplate<CellContext<TData, unknown>>,
                                    {
                                      ...cell.getContext(),
                                      getValue: (() =>
                                        getValueCell(
                                          row.original,
                                          cell.column.accessorFn,
                                          row.index,
                                          cell.column.id,
                                        )) as Getter<unknown>,
                                      getRowData: () => rowData,
                                      getCellProps: () =>
                                        ({
                                          ...props,
                                          dashIfEmpty: !!dashIfEmpty,
                                          editableMatrix: {
                                            hasTop: matrix.getTop(x, y),
                                            hasBottom: matrix.getBottom(x, y),
                                            hasLeft: matrix.getLeft(x, y),
                                            hasRight: matrix.getRight(x, y),
                                          },
                                          getType,
                                        }) as NoInfer<any>,
                                    },
                                  )}
                                </td>
                              )
                            })}
                            {selectPosition === 'right' && renderSelect(row)}
                            {!deletable &&
                              !selectable &&
                              !!createdRows?.length &&
                              !row.original.props?.isNew &&
                              (isMayReturn || (isArchived && !canReturnFromArchive)) && (
                                <SelectTd key="empty">
                                  <div className={classes.archivedCont}>&nbsp;</div>
                                </SelectTd>
                              )}
                            {!deletable &&
                              !selectable &&
                              !!createdRows?.length &&
                              !row.original.props?.isNew &&
                              !isMayReturn &&
                              !isArchived && <SelectTd key="empty">&nbsp;</SelectTd>}
                          </tr>
                        </Fragment>
                      )
                    })}
                  </tfoot>
                )}
              </table>
              {showCopyFrame && <SelectionFrame selection={selection} tableRef={tableRef} />}
            </div>
          </div>
        </div>
      )}

      <Loader color={LoaderColors.Gray} show={isFetchingNextPage} type={LoaderTypes.Paginate} />

      {!loading && copyPaste && <RowSelectArea ref={selectionAreaRef} />}
      {pagination && <Pagination {...pagination} />}

      {errorDetail && (
        <TextModal isOpened={openError} onClose={onCloseError} title={errorMessage}>
          {errorDetail}
        </TextModal>
      )}
      {showCountRowsInfo && !!maxRowCount && (
        <div className={classes.tableCountInfo}>
          {translate('tables.maxShownInfo', { rows: dataRows.length, maxRows: numberToLocalString(maxRowCount) })}
        </div>
      )}

      <TableContextMenu refContextMenuInitializer={refContextMenuInitializer} />
    </div>
  )
}

const Table = forwardRef(InternalTable) as <TData extends TRow>(
  props: TableProps<TData> & { ref?: Ref<HTMLTableElement> },
) => ReactElement

export const TableMemo = memo(Table) as typeof Table

export { Table }
