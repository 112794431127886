import { FC, useEffect, useRef } from 'react'

import { ColumnDef } from '@tanstack/react-table'
import { useInViewport } from 'ahooks'

import classes from './Table.module.scss'

type TableHeaderCellProps = ColumnDef<any> & {
  onViewPort?: (columnId: string, inViewport: boolean) => void
}

export const TableHeaderCell: FC<TableHeaderCellProps> = ({ header, id, onViewPort }) => {
  const refFake = useRef<HTMLSpanElement>(null)
  const [inViewport] = useInViewport(refFake.current, { threshold: 1 })

  useEffect(() => {
    if (id) {
      onViewPort?.(id, !!inViewport)
    }
  }, [inViewport])

  if (typeof header !== 'string') {
    return null
  }
  return (
    <>
      {header}
      <span className={classes.fakeHeaderCell} ref={refFake}>
        {header}
      </span>
    </>
  )
}
