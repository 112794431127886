import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useSyncStates } from 'hooks/useSyncStates'
import { TRow } from 'interfaces/table.interfaces'
import { uniq } from 'lodash'
import { CollapsingStrategy, RowId, RowIdNotNullable } from 'ui/Table/types'

export const useCollapsing = <TData extends TRow>(options: {
  data?: TData[] | null
  collapsingTreeKeys?: string[]
  isCollapsing?: boolean
  collapsingStrategy?: CollapsingStrategy
  expandedRows?: RowIdNotNullable[]
  setExpandedRows?: Dispatch<SetStateAction<RowIdNotNullable[]>>
  collapsingInitialOpen?: boolean
}) => {
  const [expandedRowsInternal, setExpandedRowsInternal] = useState<RowIdNotNullable[]>([])
  const [hoverParent, setHoverParent] = useState<RowId>(null)
  useSyncStates([options.expandedRows, options.setExpandedRows], [expandedRowsInternal, setExpandedRowsInternal])
  const refNestedIds = useRef<Record<RowIdNotNullable, RowIdNotNullable[]>>({})

  const getAttribute = useCallback(
    (rowId: RowId) => {
      const row = options.data?.find((r) => (r as any)?.id === rowId) as any
      return row?.attribute
    },
    [options.data],
  )

  const toggleCollapse = useCallback(
    (rowId: RowId, expanded?: boolean) => {
      if (!options.isCollapsing) {
        return
      }
      return setExpandedRowsInternal((prev) => {
        if (!rowId) {
          return prev
        }
        const id = options.collapsingStrategy === 'toggle' ? getAttribute(rowId) : rowId
        const isExpandedRow = expanded !== undefined ? !expanded : !!prev.find((expandedRow) => expandedRow === id)
        return isExpandedRow
          ? prev.filter((expandedRow) => expandedRow !== id && !refNestedIds.current[id]?.includes(expandedRow))
          : uniq([...prev, id, ...(options.collapsingStrategy === 'toggle' ? refNestedIds.current[id] : [])])
      })
    },
    [options.isCollapsing, options.collapsingStrategy, getAttribute],
  )

  const isCollapsed = useCallback(
    (rowId: RowId) => {
      const id = options.collapsingStrategy === 'toggle' ? getAttribute(rowId) : rowId
      return !!expandedRowsInternal.find(
        (expandedRow) => expandedRow === id || refNestedIds.current[expandedRow]?.includes(id as any),
      )
    },
    [options.collapsingStrategy, getAttribute, expandedRowsInternal],
  )

  const isParent = useCallback(
    (rowId: RowId) => !!Object.keys(refNestedIds.current).find((nestedId) => String(rowId) === String(nestedId)),
    [],
  )

  const formattedData = useMemo(() => {
    if (!options.isCollapsing || (options.collapsingStrategy !== 'toggle' && !options.collapsingTreeKeys)) {
      return options.data
    }
    const rows: any[] = []

    const addItems = (item: any, keyIndex: number) => {
      rows.push({ ...item, _level: keyIndex })
      if (!expandedRowsInternal.find((expandedRow) => expandedRow === item.id) || !options.collapsingTreeKeys) {
        return
      }
      const key = options.collapsingTreeKeys[keyIndex]
      const keyIndexNext = keyIndex + 1
      if (key && key in item && Array.isArray(item[key]) && item[key].length) {
        item[key].forEach((child: any) => addItems(child, keyIndexNext))
      }
    }

    const addItemsToParent = (item: any) => {
      if (!item.parent) {
        rows.push(item)
        return
      }
      if (expandedRowsInternal.find((expandedRow) => item.parent === expandedRow)) {
        rows.push(item)
      }
    }

    options.data?.forEach((row) => {
      if (options.collapsingStrategy === 'toggle') {
        addItemsToParent(row)
      } else {
        addItems(row, 0)
      }
    })

    return rows
  }, [options.data, expandedRowsInternal])

  useEffect(() => {
    if (!options.isCollapsing || (options.collapsingStrategy !== 'toggle' && !options.collapsingTreeKeys)) {
      return
    }
    const addIds = (item: any, keyIndex: number, idsArray?: RowIdNotNullable[]) => {
      if (!options.collapsingTreeKeys) {
        return
      }
      if (item.id) {
        idsArray?.push(item.id)
      }
      if (item.id && !refNestedIds.current[item.id]) {
        refNestedIds.current[item.id] = []
        idsArray = refNestedIds.current[item.id]
      }
      const key = options.collapsingTreeKeys[keyIndex]
      const keyIndexNext = keyIndex + 1
      if (key && key in item && Array.isArray(item[key]) && item[key].length) {
        item[key].forEach((child: any) => addIds(child, keyIndexNext, idsArray))
      }
    }
    const addIdsToParent = (item: any) => {
      if (item.parent) {
        if (!refNestedIds.current[item.parent]) {
          refNestedIds.current[item.parent] = []
        }
        refNestedIds.current[item.parent].push(item.attribute)
        if (!options?.expandedRows) {
          setExpandedRowsInternal((prev) => [...prev, item.attribute])
        }
      }
      if (item.isParent) {
        if (!refNestedIds.current[item.attribute]) {
          refNestedIds.current[item.attribute] = []
        }
        if (!options?.expandedRows) {
          setExpandedRowsInternal((prev) => [...prev, item.attribute])
        }
      }
    }
    options.data?.forEach((row) => {
      if (options.collapsingStrategy === 'toggle') {
        addIdsToParent(row)
        if (!options?.expandedRows) {
          setExpandedRowsInternal((prev) => uniq([...prev, ...Object.keys(refNestedIds.current).map(String)]))
        }
      } else {
        addIds(row, 0)
      }
    })
  }, [options.data])

  const isMouseOverParent = useCallback((rowId: RowId) => setHoverParent(rowId), [])
  const isMouseOutParent = useCallback(() => setHoverParent(null), [])

  return {
    expandedRowsInternal,
    toggleCollapse,
    isCollapsed,
    formattedData,
    isParent,
    isMouseOverParent,
    isMouseOutParent,
    hoverParent,
  }
}
