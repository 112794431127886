import { AccessorFn } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'

export const getValueCell = <TData extends TRow>(
  rowData: TData,
  accessorFn: AccessorFn<TData> | undefined,
  index: number,
  columnId: string,
): string | number | null => {
  const originalRow = rowData.data ?? rowData
  const data = accessorFn ? accessorFn(originalRow, index) : originalRow[columnId as keyof TRow]
  return typeof data === 'object' && data !== null && typeof data.value !== 'undefined' ? data.value : data
}
