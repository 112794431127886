import { RefObject, useCallback, useEffect, useRef } from 'react'

import { ColumnDef } from '@tanstack/table-core'
import { useLatest } from 'ahooks'
import { useMutationObserver } from 'hooks/useMutationObserver'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { TRow } from 'interfaces/table.interfaces'

export const useResizeLastColumn = <TData extends TRow>(options: {
  refWrap: RefObject<HTMLDivElement>
  tableRef: RefObject<HTMLTableElement>
  columnResizable?: boolean
  widthFullAlways?: boolean
  onResize?: (columnId: number | string, size: number, isAutoWidthLastColumn: boolean) => void
  columns?: ColumnDef<TData, any>[]
  showHeader?: boolean
}) => {
  const { refWrap, tableRef, columnResizable, widthFullAlways, onResize, columns, showHeader } = options
  const refColumns = useLatest(columns)
  const refSizeLastColumn = useRef<number | null>(null)

  const refResizeObservers = useRef<{ el: HTMLElement; observer: ResizeObserver | null }[]>([])

  const callback = useCallback(() => {
    if (!widthFullAlways || !columnResizable || !tableRef.current || !refWrap.current || !refColumns.current?.length) {
      return
    }
    const lastColumn = refColumns.current[refColumns.current.length - 1]
    if (lastColumn?.id) {
      const lastTh = (
        showHeader
          ? tableRef.current.querySelector(':scope > thead > tr > th:last-child')
          : tableRef.current.querySelector(':scope > tbody > tr:first-child > td:last-child')
      ) as HTMLTableCellElement
      const lastCells = Array.from(
        tableRef.current.querySelectorAll(':scope > tbody > tr > td:last-child:not([data-no-data=true])'),
      ) as HTMLTableCellElement[]

      const wrapWidth = refWrap.current.getBoundingClientRect().width
      const tableWidthWithoutLastColumn =
        tableRef.current.getBoundingClientRect().width - lastTh.getBoundingClientRect().width
      let widthLastColumn = wrapWidth - tableWidthWithoutLastColumn

      if (lastColumn.minSize && lastColumn.minSize > widthLastColumn) {
        widthLastColumn = lastColumn.minSize
      }
      if (lastColumn.maxSize && lastColumn.maxSize < widthLastColumn) {
        widthLastColumn = lastColumn.maxSize
      }

      refSizeLastColumn.current = widthLastColumn
      onResize?.(lastColumn.id, widthLastColumn, true)
      if (lastTh) {
        lastTh.style.width = `${widthLastColumn}px`
      }
      lastCells.forEach((cell) => {
        cell.style.width = `${widthLastColumn}px`
      })
    }
  }, [refWrap, tableRef, columnResizable, widthFullAlways, onResize, columns, showHeader])

  useResizeObserver({
    ref: refWrap,
    callback,
    enabled: !!columnResizable && !!widthFullAlways && !!tableRef.current && !!refWrap.current && !!columns?.length,
  })

  useMutationObserver({
    ref: refWrap,
    callback: () => {
      if (!tableRef.current) {
        return
      }
      const thCells = Array.from(tableRef.current.querySelectorAll(':scope > thead > tr > th:not(:last-child)'))
      thCells.forEach((th) => {
        if (!refResizeObservers.current.find((item) => item.el === th)) {
          const observer = new ResizeObserver(callback)
          refResizeObservers.current.push({
            el: th as HTMLElement,
            observer,
          })
          observer.observe(th)
        }
      })
      refResizeObservers.current.forEach((item) => {
        if (!thCells.includes(item.el)) {
          item.observer?.disconnect()
          item.observer = null
        }
      })
      refResizeObservers.current = refResizeObservers.current.filter((item) => item.observer)
    },
    options: { childList: true, subtree: true },
    enabled: !!columnResizable && !!widthFullAlways && !!tableRef.current && !!refWrap.current && !!columns?.length,
    runImmediately: true,
  })

  useEffect(
    () => () => {
      refResizeObservers.current.forEach((item) => {
        item.observer?.disconnect()
      })
    },
    [],
  )

  return { sizeLastColumn: refSizeLastColumn.current }
}
