import { createContext, MutableRefObject, ReactNode } from 'react'

export interface DraggableAppContextProps {
  draggableLists: Record<string, any>
  refCommonDraggableItem: MutableRefObject<HTMLElement | null>
}

const defaultDraggableAppContextValue: DraggableAppContextProps = {
  draggableLists: {},
  refCommonDraggableItem: { current: null },
}

export const DraggableAppContext = createContext<DraggableAppContextProps>(defaultDraggableAppContextValue)

export interface DraggableAppProviderProps extends Partial<DraggableAppContextProps> {
  children?: ReactNode
}

export const DraggableAppProvider = ({ children, ...props }: DraggableAppProviderProps): ReactNode => (
  <DraggableAppContext.Provider value={{ ...defaultDraggableAppContextValue, ...props } as any}>
    {children}
  </DraggableAppContext.Provider>
)
