export const amountScrolledTableElements = {
  recommendations: 5,
}

export type CellSize = 'small' | 'default'

export const defaultRowHeight = 33

export const smallRowHeight = 21

export const cellSizeToRowHeight: Record<CellSize, number> = {
  default: defaultRowHeight,
  small: smallRowHeight,
}
