import VeryPoorActive from 'assets/images/smile-1-active.svg?react'
import VeryPoor from 'assets/images/smile-1.svg?react'
import PoorActive from 'assets/images/smile-2-active.svg?react'
import Poor from 'assets/images/smile-2.svg?react'
import GoodActive from 'assets/images/smile-3-active.svg?react'
import Good from 'assets/images/smile-3.svg?react'
import VeryGoodActive from 'assets/images/smile-4-active.svg?react'
import VeryGood from 'assets/images/smile-4.svg?react'
import ExcellentActive from 'assets/images/smile-5-active.svg?react'
import Excellent from 'assets/images/smile-5.svg?react'

export const ratingIcons = [
  {
    icon: <VeryPoor />,
    activeIcon: <VeryPoorActive />,
    rating: 1,
  },
  {
    icon: <Poor />,
    activeIcon: <PoorActive />,
    rating: 2,
  },
  {
    icon: <Good />,
    activeIcon: <GoodActive />,
    rating: 3,
  },
  {
    icon: <VeryGood />,
    activeIcon: <VeryGoodActive />,
    rating: 4,
  },
  {
    icon: <Excellent />,
    activeIcon: <ExcellentActive />,
    rating: 5,
  },
]
