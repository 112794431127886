import { DelistingTableRow } from 'jume/delistingTable/api'
import { GetAllData } from 'packages/api'
import { PaginateData } from 'packages/react-query'

export enum DelistingTabsCodes {
  All = 'all',
  Inactive = 'inactive',
}

export type DelistingAllData = Record<DelistingTabsCodes, PaginateData<GetAllData<DelistingTableRow>> | undefined>
