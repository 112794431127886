export const isNotFitRight = ({
  windowWidth,
  elementWidth,
  left,
}: {
  windowWidth: number
  elementWidth: number
  left: number
}) => windowWidth - left < elementWidth && left > elementWidth

export const isNotFitLeft = ({ left, leftIndent }: { left: number; leftIndent: number }) => left < leftIndent
