import { ChangedCell, ExcelCellData, ExcelState, SelectedCells } from 'interfaces/excelTable.interfaces'

import { getCell } from './getCell'

export const getCellsMatrix = <State extends ExcelState = ExcelState>(
  state: State,
  selectedCells: SelectedCells,
  changedCells: ChangedCell[],
): (ExcelCellData | null)[][] => {
  const result: (ExcelCellData | null)[][] = []
  const perPage = state.perPage
  const paginateAxis = state.paginateAxis
  if (!perPage || !paginateAxis) {
    return result
  }

  const startRowIndex = Math.min(selectedCells.cells.rows.start, selectedCells.cells.rows.end)
  const endRowIndex = Math.max(selectedCells.cells.rows.start, selectedCells.cells.rows.end)
  const startColumnIndex = Math.min(selectedCells.cells.columns.start, selectedCells.cells.columns.end)
  const endColumnIndex = Math.max(selectedCells.cells.columns.start, selectedCells.cells.columns.end)

  let indexY = 0
  let indexX = 0

  for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
    indexX = 0
    for (let columnIndex = startColumnIndex; columnIndex <= endColumnIndex; columnIndex++) {
      if (!result[indexY]) {
        result[indexY] = []
      }
      result[indexY][indexX] = getCell(state, rowIndex, columnIndex)
      const cell = result[indexY][indexX]
      if (perPage && paginateAxis) {
        const changedCell = changedCells.find((item) => {
          const prevCount = (item.location.page - 1) * perPage
          const indexByRow = paginateAxis === 'y' ? rowIndex - prevCount : rowIndex
          const indexByColumn = paginateAxis === 'x' ? columnIndex - prevCount : columnIndex
          return item.location.indexes[0] === indexByRow && item.location.indexes[1] === indexByColumn
        })
        if (changedCell && cell) {
          cell.value = changedCell.value
        }
      }
      indexX++
    }
    indexY++
  }
  return result
}
