import { forwardRef, MouseEventHandler, ReactElement, useEffect } from 'react'

import { useSafeState } from 'ahooks'
import cx from 'clsx'
import { equalChildren } from 'utils/equalChildren'

import classes from './Animation.module.scss'

export enum AnimationTypes {
  ScaleIn = 'scaleIn',
  ScaleInBottomLeft = 'scaleInBottomLeft',
  ScaleInBottom = 'scaleInBottom',
  ScaleInBottomRight = 'scaleInBottomRight',
  ScaleInTopLeft = 'scaleInTopLeft',
  ScaleInTop = 'scaleInTop',
  ScaleInTopRight = 'scaleInTopRight',
  ScaleInMini = 'scaleInMini',
  ScaleInMiniBottomLeft = 'scaleInMiniBottomLeft',
  ScaleInMiniBottom = 'scaleInMiniBottom',
  ScaleInMiniBottomRight = 'scaleInMiniBottomRight',
  ScaleInMiniTopLeft = 'scaleInMiniTopLeft',
  ScaleInMiniTop = 'scaleInMiniTop',
  ScaleInMiniTopRight = 'scaleInMiniTopRight',
  ScaleInMax = 'scaleInMax',
  ScaleUpMax = 'scaleUpMax',
  SlideUp = 'slideUp',
  SlideUpMini = 'slideUpMini',
  SlideUpScale = 'slideUpScale',
  SlideUpScaleMax = 'slideUpScaleMax',
  Opacity = 'opacity',
  ScaleToDown = 'scaleToDown',
  ScaleToUp = 'scaleToUp',
}

interface AnimationProps {
  children?: any
  disabled?: boolean
  visible?: boolean
  initVisible?: boolean
  className?: string
  classNameHide?: string
  classNameIsHide?: string
  classNameIsShow?: string
  type?: AnimationTypes
  delay?: number
  delayAnimationShow?: number
  duration?: number
  onClick?: MouseEventHandler
  stopPropagation?: boolean
}

const durationMs = {
  [AnimationTypes.ScaleIn]: 150,
  [AnimationTypes.ScaleInBottomLeft]: 150,
  [AnimationTypes.ScaleInBottom]: 150,
  [AnimationTypes.ScaleInBottomRight]: 150,
  [AnimationTypes.ScaleInTopLeft]: 150,
  [AnimationTypes.ScaleInTop]: 150,
  [AnimationTypes.ScaleInTopRight]: 150,
  [AnimationTypes.ScaleInMini]: 150,
  [AnimationTypes.ScaleInMiniBottomLeft]: 150,
  [AnimationTypes.ScaleInMiniBottom]: 150,
  [AnimationTypes.ScaleInMiniBottomRight]: 150,
  [AnimationTypes.ScaleInMiniTopLeft]: 150,
  [AnimationTypes.ScaleInMiniTop]: 150,
  [AnimationTypes.ScaleInMiniTopRight]: 150,
  [AnimationTypes.ScaleInMax]: 200,
  [AnimationTypes.ScaleUpMax]: 200,
  [AnimationTypes.SlideUp]: 200,
  [AnimationTypes.SlideUpMini]: 200,
  [AnimationTypes.SlideUpScale]: 200,
  [AnimationTypes.SlideUpScaleMax]: 200,
  [AnimationTypes.Opacity]: 200,
  [AnimationTypes.ScaleToDown]: 250,
  [AnimationTypes.ScaleToUp]: 250,
}

export const Animation = forwardRef<HTMLDivElement, AnimationProps>(
  (
    {
      children,
      disabled,
      visible,
      initVisible,
      className,
      classNameHide,
      classNameIsHide,
      classNameIsShow,
      type = AnimationTypes.SlideUp,
      delay = 0,
      delayAnimationShow = 0,
      duration,
      onClick,
      stopPropagation,
    },
    ref,
  ) => {
    const [stateChildren, setStateChildren] = useSafeState<ReactElement | null>(null)
    const [animHide, setAnimHide] = useSafeState(!initVisible)
    const [isHide, setIsHide] = useSafeState(!initVisible)

    const durationInternal = disabled ? 0 : duration ?? durationMs[type]

    const onClickInternal: MouseEventHandler = (e) => {
      if (stopPropagation) {
        e.stopPropagation()
      }
      if (onClick) {
        onClick(e)
      }
    }

    useEffect(() => {
      let st1: NodeJS.Timeout
      let st2: NodeJS.Timeout
      let st3: NodeJS.Timeout
      let st4: NodeJS.Timeout
      let st5: NodeJS.Timeout
      let st6: NodeJS.Timeout
      let st7: NodeJS.Timeout
      let st8: NodeJS.Timeout

      const clearTimeouts = () => {
        clearTimeout(st1)
        clearTimeout(st2)
        clearTimeout(st3)
        clearTimeout(st4)
        clearTimeout(st5)
        clearTimeout(st6)
        clearTimeout(st7)
        clearTimeout(st8)
      }

      if (!children) {
        setAnimHide(true)
        st8 = setTimeout(() => {
          setStateChildren(null)
          setIsHide(true)
        }, durationInternal + delay)
        return clearTimeouts
      }
      if (children && !stateChildren) {
        const show = () => {
          setAnimHide(false)
        }
        const showChildren = () => {
          setStateChildren(children)
          setIsHide(false)
          setAnimHide(true)
          if (delayAnimationShow) {
            st3 = setTimeout(show, 10 + delayAnimationShow)
          } else {
            show()
          }
        }
        if (delay) {
          st1 = setTimeout(showChildren, delay)
        } else {
          showChildren()
        }
      } else if (!children && stateChildren) {
        setAnimHide(true)
        setIsHide(false)
        st4 = setTimeout(() => {
          if (!disabled) {
            setStateChildren(children)
          }
        }, durationInternal + delay)
      } else if (children && stateChildren && !equalChildren(children, stateChildren)) {
        setAnimHide(true)
        setIsHide(false)
        st5 = setTimeout(() => {
          setStateChildren(children)
          st6 = setTimeout(() => {
            setAnimHide(false)
          }, 10 + delayAnimationShow)
        }, durationInternal + delay)
      } else if (children && stateChildren && equalChildren(children, stateChildren)) {
        setStateChildren(children)
        setIsHide(false)
        st7 = setTimeout(() => {
          setAnimHide(false)
        }, 10 + delayAnimationShow)
      }

      return clearTimeouts
    }, [children])

    useEffect(() => {
      if (visible) {
        setStateChildren(children)
        setAnimHide(false)
      }
    }, [visible, children])

    return (
      <div
        className={cx(
          classes.anim,
          className,
          classes[type],
          { [classes.hide]: animHide },
          classNameHide && { [classNameHide]: animHide },
          classNameIsHide && { [classNameIsHide]: isHide },
          classNameIsShow && { [classNameIsShow]: !isHide },
        )}
        onClick={onClickInternal}
        ref={ref}
        style={{ transitionDuration: `${(initVisible && !stateChildren) || disabled ? 0 : durationInternal}ms` }}
      >
        {(initVisible && !stateChildren) || disabled ? children : stateChildren}
      </div>
    )
  },
)
