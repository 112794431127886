import { Row } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'

export const getRowId = <TData extends TRow>(row: Row<TData>) =>
  (row.original as any).id?.value ??
  (row.original as any).id ??
  (row.original as any).idCreated ??
  row.original.data?.id?.value ??
  row.original.data?.id ??
  row.original.data?.idCreated
