import { Dispatch, SetStateAction } from 'react'

import { useDeepCompareEffect } from 'ahooks'
import { isEqual } from 'lodash'

export const useSyncStates = <S>(
  param1: [S | undefined, Dispatch<SetStateAction<S>> | undefined],
  param2: [S | undefined, Dispatch<SetStateAction<S>> | undefined],
) => {
  useDeepCompareEffect(() => {
    if (typeof param1[0] !== 'undefined' && typeof param2[0] !== 'undefined' && !isEqual(param1[0], param2[0])) {
      param2[1]?.(param1[0])
    }
  }, [param1[0]])

  useDeepCompareEffect(() => {
    if (typeof param1[0] !== 'undefined' && typeof param2[0] !== 'undefined' && !isEqual(param1[0], param2[0])) {
      param1[1]?.(param2[0])
    }
  }, [param2[0]])
}
