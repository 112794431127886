import { useQuery } from '@tanstack/react-query'
import { jobLockApi, JobLockStatus } from 'demand/jobLock/api'
import { useQueryClient } from 'packages/react-query'

import { JobLockQueries } from './types'

export const useJobLockStatus = () => {
  const queryClient = useQueryClient()

  const { data, isPending, error } = useQuery({
    queryKey: [JobLockQueries.JobLockStatus],
    queryFn: () => jobLockApi.getJobLockStatus(),
    meta: {
      error: {
        showToast: false,
      },
    },
  })

  const clearJobLockStatus = () => queryClient.removeQueries({ queryKey: [JobLockQueries.JobLockStatus] })
  const setJobLockStatus = (newData: JobLockStatus) =>
    queryClient.setQueryData([JobLockQueries.JobLockStatus], () => newData)

  return {
    jobLock: data,
    isLoading: isPending,
    error,
    clearJobLockStatus,
    setJobLockStatus,
  }
}
