import { AttributeFilterData, AttributeFilterPartial } from 'modules/filters/attribute/api'

export enum JobStatuses {
  InProgress = 'In progress',
  Finished = 'Finished',
  Scheduled = 'Scheduled',
  NotScheduled = 'Not scheduled',
  BeingCancelled = 'Being cancelled',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
}

export interface JobData {
  id: number
  name: string
  emailsToNotify: EmailToNotify[]
  start?: string
  endDate: string | null
  intervalDays: number | null
  intervalWeeks: number | null
  intervalMonth: number | null
  numberOfOccurrences: number | null
  intervalYears: number
  recurrencePattern: string | null
  daysOfWeek: string[] | null
  dayOfMonth: number | null
  index: string | null
  monthOfYear: number[] | null
  plannedStart: string | null
  hasLog: boolean
  startBy: string | null
  status: JobStatuses | null
  actualStart: string | null
  duration: string | null
  executionEnd: string | null
  canStart: boolean
}

export interface JobDataPayload {
  id?: number
  name: string
  emailsToNotify?: EmailToNotify[]
  start?: string
  hasEnd?: boolean
  endDate?: string
  intervalDays?: number
  intervalWeeks?: number
  intervalMonth?: number | null
  numberOfOccurrences?: number
  intervalYears?: number
  recurrencePattern?: string
  daysOfWeek?: string[]
  dayOfMonth?: number
  index?: string
  monthOfYear?: number[]
}

export interface EmailToNotify {
  id?: number
  email?: string
  cases?: string[]
}

export interface StepType {
  id: number
  name: string
  stream: number
}

export interface StepData {
  anotherJob?: number | null
  demandFilter?: AttributeFilterData | AttributeFilterPartial | null | undefined | number
  functionName?: string | null
  functionOperation?: string | null
  id?: number
  kfDestinationCodes?: string[]
  kfDestinationEnd?: string
  kfDestinationStart?: string
  kfDestinationEndTimePeriod?: number
  kfDestinationStartTimePeriod?: number
  kfDestinationTimePeriodType?: number | null
  kfSourceCode?: string | null
  kfSourceEnd?: string
  kfSourceStart?: string
  kfSourceStartTimePeriod?: number
  kfSourceEndTimePeriod?: number
  name: string
  order: number
  system?: string | null
  template?: string | null
  timePeriodType?: number | null
  type: number
  status?: JobStatuses | null
  hasLog?: boolean
  howManyDaysAgo?: number | null
  validationTemplate?: number | null
}

export interface StepDataPayload extends Omit<StepData, 'demandFilter'> {
  demandFilter?: number | null
}

export interface QueryStepData {
  anotherJob: number
  demandFilter: number
  functionName: string
  functionOperation: string
  id: number
  kfDestinationCodes: string[]
  kfDestinationEnd?: string
  kfDestinationStart?: string
  kfSourceStartTimePeriod?: number
  kfSourceEndTimePeriod?: number
  kfDestinationStartTimePeriod?: number
  kfDestinationEndTimePeriod?: number
  kfDestinationTimePeriodType?: number | null
  kfSourceCode: string
  kfSourceEnd?: string
  kfSourceStart?: string
  lastDateShifting: null
  hasLog: boolean
  name: string
  order: number
  status: JobStatuses | null
  system: string
  template: string
  timePeriodType: number
  type: number
  howManyDaysAgo: number | null
  validationTemplate: number | null
}

export interface ValidationTemplate {
  id: number
  name: string
  systemName: string
  filePath: string
  externalSystem: string
}

export interface KeyFigure {
  id: string
  name: string
  code: string
}

export interface JobsStatusFilter {
  plannedStartBefore?: string
  plannedStartAfter?: string
  actualStartBefore?: string
  actualStartAfter?: string
  status: string[]
}

export enum JobsSortingType {
  Status = '-status',
}
