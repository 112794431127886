import { createContext, MutableRefObject, ReactNode } from 'react'

interface DraggableContextProps {
  refDraggableItem: MutableRefObject<HTMLElement | null>
  refMetaItems: MutableRefObject<any[]>
}

const defaultDraggableContextValue: DraggableContextProps = {
  refDraggableItem: { current: null },
  refMetaItems: { current: [] },
}

export const DraggableContext = createContext<DraggableContextProps>(defaultDraggableContextValue)

export interface DraggableProviderProps extends Partial<DraggableContextProps> {
  children?: ReactNode
}

export const DraggableProvider = ({ children, ...props }: DraggableProviderProps): ReactNode => (
  <DraggableContext.Provider value={{ ...defaultDraggableContextValue, ...props } as any}>
    {children}
  </DraggableContext.Provider>
)
